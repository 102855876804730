var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    {
      staticClass: "iq-dropdown",
      attrs: { trigger: "click", type: "primary" },
      on: { click: _vm.handleClick, command: _vm.handleCommand },
    },
    [
      _c(
        "div",
        { class: ["iq-dropdown__trigger", "el-dropdown-link", _vm.color] },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
      _c(
        "el-dropdown-menu",
        {
          class: { mobileDropdown: _vm.isMobile },
          attrs: { slot: "dropdown" },
          slot: "dropdown",
        },
        _vm._l(_vm.optionsList, function (item) {
          return _c(
            "el-dropdown-item",
            {
              key: item.id,
              staticClass: "ta-l p-l-33 p-r-33 p-t-10 p-b-10",
              attrs: { command: item[_vm.keyCommand] },
            },
            [_vm._v(" " + _vm._s(item[_vm.keyLabel]) + " ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }