<template>
  <el-dropdown
    class="iq-dropdown"
    trigger="click"
    type="primary"
    @click="handleClick"
    @command="handleCommand"
  >
    <div :class="['iq-dropdown__trigger', 'el-dropdown-link', color]">
      {{ title }}
    </div>

    <el-dropdown-menu slot="dropdown" :class="{ mobileDropdown: isMobile }">
      <el-dropdown-item
        v-for="item in optionsList"
        :key="item.id"
        :command="item[keyCommand]"
        class="ta-l p-l-33 p-r-33 p-t-10 p-b-10"
      >
        {{ item[keyLabel] }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { GET_IS_MOBILE } from '@/store/actions'
import { mapGetters } from 'vuex'

export default {
  name: 'IqDropDown',
  props: {
    title: {
      type: String,
      default: '',
    },
    optionsList: {
      type: Array,
      required: true,
      default: () => [],
    },
    keyCommand: {
      type: String,
      default: 'id',
    },
    keyLabel: {
      type: String,
      default: 'label',
    },
    color: {
      type: String,
      default: 'orange',
    },
  },
  computed: {
    ...mapGetters({
      isMobile: GET_IS_MOBILE,
    }),
  },
  methods: {
    handleClick(event) {
      this.$emit('onClickButton', event)
    },
    handleCommand(event) {
      this.$emit('onCommand', event)
    },
  },
}
</script>

<style lang="sass" scoped>
.mobileDropdown
  width: 100vw
  left: 0 !important

.iq-dropdown
  min-width: 226px
  @media  (max-width: 600px)
    width: 100% !important
  &__trigger
    padding: 9px 70px 9px 35px
    font-family: Roboto, sans-serif
    font-weight: 600
    font-size: 14px
    line-height: 22px
    font-feature-settings: 'tnum' on, 'lnum' on
    color: $color-white
    background: $color-orange-primary
    border-radius: 4px
    position: relative
    @media  (max-width: 600px)
      width: 100% !important
    &::after
      content: ''
      display: block
      width: 1px
      height: 22px
      position: absolute
      top: 50%
      right: 55px
      transform: translateY(-50%)
      background: $color-white
    &::before
      content: ''
      display: block
      width: 9px
      height: 9px
      position: absolute
      top: 42%
      right: 22px
      transform: translateY(-50%) rotate(45deg)
      border-right: 2px solid #FFFFFF
      border-bottom: 2px solid #FFFFFF
      border-radius: 1px
    &.orange
      color: $color-white
      background: $color-orange-primary
</style>
